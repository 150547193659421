@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --Telescopic-Blue: #192938;
  --Comsic-Black: #0d171f;
  --Aqua-Fever: #0998a8;
  --Abyss: #0B5864;
  --Tealish: #0A7886;
  --primary: var(--Comsic-Black);
  --second: var(--Aqua-Fever);
  --bgColor: var(--Telescopic-Blue);
  --mps: var(--Abyss);
  --mpsp: rgba(11, 88, 100, 0.5);
  --mps2: var(--Tealish);
  --mpsp2: rgba(25, 41, 56, 0.6);
}

/* Tailwind-based classes */
body {
  background-color: var(--bgColor);
  color: var(--primary);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary);
}
